import React from 'react';
import '../App.css';
import Theme from '../theme';
import OwenPhoto from "../assets/img/owen_headshot.png";
import PardesPhoto from "../assets/img/pardes_headshot.png";
import NysPhoto from "../assets/img/nys_headshot.png";
import KhuzamPhoto from "../assets/img/khuzam_headshot.png";
import WoodsPhoto from "../assets/img/woods_headshot.png";
import LukaPhoto from "../assets/img/luka_headshot.png";
import JenyaPhoto from "../assets/img/jenya_headshot.jpg";
import DenisPhoto from "../assets/img/baranovskyi_headshot.png";

class Team extends React.Component{
  constructor(props){
    super(props);
    this.state={   
        
    };

  }


  componentDidMount(){

  }

  render() {

    let teamBios = [
      {
        image:OwenPhoto,
        name: "Teddy Owen",
        title:"Partner, CEO",
        bio:`Teddy graduated from Colgate University where he studied Computer Science and Economics. 
        In college, he ran a small storage and shipping business, and then went to work in investment banking doing M&A before co-founding Tuliphedge, 
        a cloud platform where college investment clubs collaborate, 
        compete and apply for jobs. Teddy is experienced in full stack software engineering and dev ops. 
        He has led projects for mobile apps, VR apps, Alexa Skills, web apps and more.`,
      },
      {
        image:JenyaPhoto,
        name: "Jenya Nemirovsky",
        title:"Design Lead",
        bio:`First and foremost, a creative leader with a foundation in visual design, Jenya is a blend of forward-thinking innovation and grounded expectations, with a proven track record of delivering outstanding projects for clients like Chase, J.P. Morgan, OppenheimerFunds, Ogilvy & Mather, and many more high profile companies.
        Pulling from his expertise in UI and UX design, interaction, mobile and responsive design, branding and print design, Jenya creates new experiences that inspire and drive business goals home.`,
      },
      {
        image:PardesPhoto,
        name:"Aaron Pardes",
        title:"Back-end Team Lead",
        bio:`Aaron has 15 years experience building web applications and has worked with businesses ranging 
          from startups to the Fortune 50. 
          He has a BS in Industrial and Systems Engineering which gives him a 
          unique perspective on software challenges. 
          He now leads our back-end team responsible for building RESTful 
          API's and integrating with third-party services.`,
      },
      {
        image:NysPhoto,
        name:"Dillon Nys",
        title:"Mobile App Team Lead",
        bio:`Dillon graduated with a degree in Environmental Engineering and worked at a consulting company in the industry. 
          Dillon has been coding since he was in high school, and his entrepreneurial mindset helped him foster a knowledge and 
          appreciation for app development which he brings to AIsoft.
          Dillon leads our mobile app team building cross-platform apps 
          in Flutter and Dart.`,
      },
      {
        image:DenisPhoto,
        name:"Denis Baranovskyi",
        title:"E-commerce Team Lead",
        bio:`Denis is a software engineer with a 15+ year track record and expertise 
        in full life-cycle E-commerce application design, development and delivery. 
        He specializes in delivering complex Shopify solutions for small and large businesses. Denis graduated 
        from the Chernihiv National University of Technology with a degree in Computer Engineering.`,
      },
      {
        image:KhuzamPhoto,
        name:"Mark Khuzam",
        title:"Front-end Team Lead",
        bio:`Mark graduated with a degree in Biomechanics from 
          California State University Northridge. He expanded into 
          software shortly after graduation and has accumulated 5 
          years of building scalable software applications. He’s 
          led Front-end development on several projects including 
          high impact government applications and eCommerce stores.
          Mark leads our front-end team building web app UIs with React.`,
      },
      {
        image:LukaPhoto,
        name:"Matt Luka",
        title:"Back-end Software Developer",
        bio:`Matt is studying computer science at Boston University, 
          and has been coding ever since before he was in high school, 
          bringing a unique passion to the craft. At AIsoft, 
          Matt has built out many of our API server back-ends for mobile apps, web apps, 
          Alexa Skills and more, often using Django, Python and PostgreSQL.`,
      },
      {
        image:WoodsPhoto,
        name:"Ryan Woods",
        title:"Front-end Software Developer",
        bio:`After studying Physics and German at Colgate University, 
          Ryan attended an electrical engineering Master's program at Brown University, 
          which he left after finding a passion for software development. This passion led him to the highly 
          selective App Academy boot camp, based out of San Francisco, where he honed his software development skills. 
          At AIsoft, Ryan focuses on front-end development in React and Redux, 
          creating powerful UIs for web apps and admin panels.`,
      },
    ];
    return(
      <div style={{        
      }}
      >
        {teamBios.map((bio, index)=>(
          <React.Fragment>
            <Bio 
              reverse={index%2==1}
              image={bio.image}
              name={bio.name} 
              title={bio.title} 
              bio={bio.bio}
            />
            <div style={{
              marginTop:20,
            }}></div>
          </React.Fragment>
        ))

        }

      </div>
    );
  }

}

class Bio extends React.Component{
  constructor(props){
    super(props);
    this.state={  
      isMobile:window.innerWidth <= 760,     
    };

  }


  componentDidMount(){

  }

  render() {
    return(

        <div style={{
          display:"flex",
          flexDirection: !this.state.isMobile ? (this.props.reverse ? "row-reverse":"row"):"column",
          alignItems:this.state.isMobile ? "center":"start",
          backgroundColor:"rgba(0,0,0,0.5)",
          padding:20,
          borderRadius:20,
        }}>
          <img 
          style={{
            flexShrink:0,
          }}
          src={this.props.image} 
          width={261} 
          height={261}  
          alt={"headshot"}/>
          <div style={{
            marginLeft:25,
            marginTop:this.state.isMobile ? 25:0,
          }}>
          </div>
          <div style={{
            display:"flex",
            flexDirection:"column",
            alignItems:this.state.isMobile ? "center":"start",
          }}>
            <div style={{
              fontFamily:Theme.primaryFont,
              color:"white",
              fontSize:33,
              marginTop:-10,
              textAlign:this.state.isMobile ? "center":"start",
            }}>
              {this.props.name}
            </div>
            <div style={{
              fontFamily:Theme.primaryFont,
              color:Theme.color2,
              fontSize:22,
              marginTop:4,
              textAlign:this.state.isMobile ? "center":"start",
            }}>
              {this.props.title}
            </div>
            <div style={{
              fontFamily:Theme.secondaryFont,
              color:"white",
              fontSize:15,
              marginTop:25,
              textAlign:this.state.isMobile ? "center":"start",
            }}>
              {this.props.bio}
            </div>
          </div>
        </div> 

    );
  }

}


export default Team;
