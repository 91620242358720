import React from 'react';
import '../App.css';
import Theme from '../theme';


import DeviceDetect from "../services/device.service";

import SearchIcon from '@material-ui/icons/Search';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BrushIcon from '@material-ui/icons/Brush';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import SpeedIcon from '@material-ui/icons/Speed';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';


class Process extends React.Component{
  constructor(props){
    super(props);
    this.state={   
        
    };

  }

  componentDidMount(){

  }

  render() {
    return(
      <div style={{
        display:"flex",
        flexDirection:"column",
        width:"100%",
    
      }}
      >
        <ProcessItem
        reverse={false}
        icon={<SearchIcon style={{ fontSize: 40 }}/>}
        title={"Assess"}
        detail={`
          In-depth meetings with the product manager to discuss the problem in detail and explore solutions
        `}
        />

        <ProcessItem
        reverse={true}
        icon={<AssignmentIcon style={{ fontSize: 40 }}/>}
        title={"Propose"}
        detail={`
          Deliverable outlining cost estimate, UI overview and tech architecture
        `}
        />

        <ProcessItem
        reverse={false}
        icon={<BrushIcon style={{ fontSize: 40 }}/>}
        title={"Design"}
        detail={`
          Work closely with the designer and product manager to craft the visual for the vision
        `}
        />

        <ProcessItem
        reverse={true}
        icon={<AutorenewIcon style={{ fontSize: 40 }}/>}
        title={"Develop"}
        detail={`
          Employ the Agile process to deliver the solution in incremental sprints of useable software
        `}
        />

        <ProcessItem
        reverse={false}
        icon={<SpeedIcon style={{ fontSize: 40 }}/>}
        title={"Beta Test"}
        detail={`
          Supported use with test users to ensure smooth operation
        `}
        />

        <ProcessItem
        reverse={true}
        icon={<FlightTakeoffIcon style={{ fontSize: 40 }}/>}
        title={"Launch"}
        detail={`
          Deliver a live version for users to begin exploring
        `}
        />

        <ProcessItem
        reverse={false}
        icon={<TrendingUpIcon style={{ fontSize: 40 }}/>}
        title={"Support & Grow"}
        detail={`
          Ongoing support and feature additions
        `}
        />

      </div> 
    );
  }

}

class ProcessItem extends React.Component{
  constructor(props){
    super(props);
    this.state={   
        
    };

  }

  componentDidMount(){

  }

  render() {
    return(
      <div style={{   
        height:120,       
        display:"flex",
        flexDirection:DeviceDetect.isMobileOrTablet() ? "row-reverse":(this.props.reverse ? "row-reverse":"row"),
        justifyContent:DeviceDetect.isMobileOrTablet() ? "flex-end":"space-between",
        marginBottom:25,
      }}>
        <div style={{
          display:"flex",
          flexDirection:"column",
          width:DeviceDetect.isMobileOrTablet() ? 310:355,
          marginLeft:DeviceDetect.isMobileOrTablet() ? 20:0,
        }}>
          <div style={{
            display:"flex",
            alignItems:"center", 
            color:"white",
            fontFamily:Theme.primaryFont,  
            fontSize:30,           
          }}>
            {this.props.icon}
            <span style={{
              marginLeft:20,
            }}>
              {this.props.title}
            </span>
          </div>
          <div style={{
            marginTop:10,
            color:"white",
            fontFamily:Theme.secondaryFont,  
            fontSize:18,           
          }}>
            {this.props.detail}
          </div>
        </div>
        <div style={{
          display:"flex",
          flexDirection:"column",
          justifyContent:"space-between",
          alignItems:"center",
        }}>

          <div style={{  
            width:10,
            height:20,
            backgroundColor:Theme.color2,
            borderRadius:2000,
          }}>
            &nbsp;
          </div>
          <div style={{  

            width:18,
            height:18,
            borderStyle:"solid",
            borderWidth:8,
            borderColor:Theme.color2,
            borderRadius:2000,
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
          }}>
          </div>
          <div style={{  
            width:10,
            height:20,
            backgroundColor:Theme.color2,
            borderRadius:2000,
          }}>
            &nbsp;
          </div>
        </div>
        {!DeviceDetect.isMobileOrTablet() &&
          <div style={{
            width:355,
          }}>
          </div>
        }
          
      </div>
    );
  }

}

export default Process;
