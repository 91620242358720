import React from 'react';
import '../App.css';
import Theme from '../theme';
import PointCursor from "../assets/img/point_cursor.png";
import AIsoftBadgeImage from "../assets/img/aisoft-badge.png";
import AppleBadgeImage from "../assets/img/app_store_badge.svg";
import GooglePlayBadgeImage from "../assets/img/google-play-badge.png";
import AlexaBadgeImage from "../assets/img/alexa-badge.png";

import DeviceDetect from "../services/device.service";

import Button from '@material-ui/core/Button';

import Fab from '@material-ui/core/Fab';

class PrimaryButton extends React.Component{
  constructor(props){
    super(props);
    this.state={
      hover:false,
    };

  }


  componentDidMount() {}


  render() {
    return(
      <Button variant="contained" 
      startIcon={this.props.icon}
      style={{
        outline:"none",
        borderStyle:"solid",
        borderColor:"white",
        borderWidth:2,
        minWidth:180,
        fontFamily:Theme.primaryFont,
        fontSize:20,
        color:this.state.hover ? Theme.color5:"white",
        backgroundColor:this.state.hover ? "white":"rgba(0,0,0,0.2)",
        fontWeight:700,
        borderRadius:100,
        // textTransform:"lowercase",
        textTransform:"uppercase",
        // cursor:"pointer",
        cursor:`url(${PointCursor}), auto`,
        boxShadow:this.state.hover ? "0px 0px 10px 3px rgba(255, 255, 255, 0.8)":"0px 0px 5px 1px rgba(255, 255, 255, 0.8)",
      }}
      onMouseEnter={() => {
        if(!DeviceDetect.isMobileOrTablet()){
          this.setState({hover:true});
        }         
      }}
      onMouseLeave={() => this.setState({hover:false})}
      onClick={this.props.onClick}
      onTouchStart={this.props.onTouchStart}
      onTouchEnd={this.props.onTouchEnd} 
      >        
        {this.props.title}
      </Button>
    );
  }

}

class FloatingActionButton extends React.Component{
  constructor(props){
    super(props);
    this.state={
      hover:false,
    };

  }


  componentDidMount() {}


  render() {
    return(

          <Fab 
          // color="primary" 
          aria-label="add"
          style={{
            cursor:`url(${PointCursor}), auto`,
            // color:Theme.color1,
            // backgroundColor:"white",
            transform: `rotate(${this.props.rotate}deg)`,
            color:this.state.hover ? Theme.color5:"white",
            backgroundColor:this.state.hover ? "white":"transparent",
            borderStyle:"solid",
            borderColor:this.props.border ? "white":"none",
            borderWidth:this.props.border ? 2:0,
          }}
          onMouseEnter={() => {
            if(!DeviceDetect.isMobileOrTablet()){
              this.setState({hover:true});
            }         
          }}
          onMouseLeave={() => this.setState({hover:false})}
          onClick={this.props.onClick}
          >            
              {this.props.icon}
          </Fab>
    );
  }

}

class LinkBadge extends React.Component{
  constructor(props){
    super(props);
    this.state={
      hover:false,
    };

  }


  componentDidMount() {}

  render() {
    return(
      <a href={this.props.url} target="_blank" rel="noopener noreferrer"
      style={{
        cursor:`url(${PointCursor}), auto`,
      }}
      >
        {this.props.variant === "aisoft" &&
          <img src={AIsoftBadgeImage} alt={"AIsoft Badge"} width={120} height={40}/>
        }
        {this.props.variant === "apple" &&
          <img src={AppleBadgeImage} alt={"App Store Badge"} width={120} height={40}/>
        }
        {this.props.variant === "google" &&
          <img src={GooglePlayBadgeImage} alt={"Google Play Badge"} width={130} height={40}/>
        }
        {this.props.variant === "alexa" &&
          <img src={AlexaBadgeImage} alt={"Alexa Badge"} width={120} height={50}/>
        }
      </a>
    );
  }

}


export {PrimaryButton, FloatingActionButton, LinkBadge};
