import React from 'react';
import '../App.css';
import Theme from '../theme';

import {PrimaryButton} from "./buttons.component";

import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import PublicIcon from '@material-ui/icons/Public';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import { SvgIcon } from "@material-ui/core";

import PointCursor from "../assets/img/point_cursor.png";
import {ReactComponent as MediumLogoSVG }from "../assets/img/medium-logo.svg";

class Contact extends React.Component{
  constructor(props){
    super(props);
    this.state={   
        
    };

  }


  componentDidMount(){

  }

  render() {
    return(
      <div style={{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        color:"white",
        fontFamily:Theme.secondaryFont,      
      }}
      >
        <div style={{
          marginBottom:40,
        }}>
          <PrimaryButton 
          title={"Reach Out"} 
          icon={<EmailIcon />}
          onClick={()=>{
            window.location.href = "mailto:contact@aisoft.dev";
          }}/>
        </div>
        <EmailIcon/>
        <h3 style={{
          marginTop:10,
          marginBottom:30,
        }}>
          contact@aisoft.dev
        </h3>
        <PhoneIcon/>
        <h3 style={{
          marginTop:10,
          marginBottom:30,
        }}>
          (415) 390-5663
        </h3>
        <PublicIcon/>
        <h3 style={{
          marginTop:10,
          marginBottom:40,
        }}>
        Located in the D.C. Metro Area
        </h3>

        <div style={{
          alignSelf:"stretch",
          display:"flex",
          alignItems:"center",
          justifyContent:"space-around",
          // width:"100%",
        }}>
          <a 
          style={{
            color:"white",
            textDecoration:"none",
            cursor:`url(${PointCursor}), auto`,
          }}
          href="https://medium.com/aisoft" 
          target="_blank" 
          rel="noopener noreferrer">            
            <SvgIcon component={MediumLogoSVG} style={{ fontSize: 31 }} />
          </a>
          <a 
          style={{
            color:"white",
            textDecoration:"none",
            cursor:`url(${PointCursor}), auto`,
          }}
          href="https://www.linkedin.com/company/aisoft-dev" 
          target="_blank" 
          rel="noopener noreferrer">            
            <LinkedInIcon style={{ fontSize: 40 }}/>
          </a>
          <a 
          style={{
            color:"white",
            textDecoration:"none",
            cursor:`url(${PointCursor}), auto`,
          }}
          href="https://twitter.com/aisoftdev" 
          target="_blank" 
          rel="noopener noreferrer">            
            <TwitterIcon style={{ fontSize: 40 }}/>
          </a>
          <a 
          style={{
            color:"white",
            textDecoration:"none",
            cursor:`url(${PointCursor}), auto`,
          }}
          href="https://www.facebook.com/aisoftdev" 
          target="_blank" 
          rel="noopener noreferrer">            
            <FacebookIcon style={{ fontSize: 40 }}/>
          </a>
        </div>

      </div> 
    );
  }

}


export default Contact;
