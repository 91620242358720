
class Theme{

  static primaryFont="'Jura', sans-serif";
  static secondaryFont="'Montserrat', sans-serif";

  // static color1="#232323";
  static color1="#260048";
  static color2="#EF2D79";
  static color3="#FFFFFF";
  static color4="#979797";
  static color5="#5c00ff";


}

export default Theme;
