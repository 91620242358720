import React from 'react';
import '../App.css';
import Theme from '../theme';
import InProgress from "../assets/img/in_progress.png";
import PointCursor from "../assets/img/point_cursor.png";
import DeviceDetect from "../services/device.service";

import Sheds from "../assets/img/605sheds_logo.png";
import TuliphedgeLogo from "../assets/img/tuliphedge_square_logo.png";
import GrillmeLogo from "../assets/img/grillme_feature_graphic.png";
import OptimalNetworks from "../assets/img/optimal-networks.png";
import Sine from "../assets/img/sine-explorer-thumbnail.png";
import BVM from "../assets/img/bvm-rate.png";
import AlexaLogo from "../assets/img/alexa_logo.png";
import SquareLogo from "../assets/img/square-logo-black.png";
import PitchCompetition from "../assets/img/competition_logo.png";
import Gmail from "../assets/img/gmail_full_logo.jpg";
import JobBoard from "../assets/img/apply_button.png";
import DogGoneDarkBG from "../assets/img/dog-gone-square-logo-dark-bg.png";
import DogGoneLightBG from "../assets/img/dog-gone-square-logo-light-bg.png";
import VuluskoLogo from "../assets/img/vulusko-horizontal-logo.png";
import UnionNewsLogo from "../assets/img/union_news_website_logo_transparent_background.png";
import CallpopLogoBlack from "../assets/img/callpop-logo-black.webp";
import CallpopLogoWhite from "../assets/img/callpop-logo-white.webp";
import AvproLogoGold from "../assets/img/avpro-logo-gold.png";
import AvproLogoBlack from "../assets/img/avpro-logo-black.jpg";




import { FloatingActionButton} from "./buttons.component";
import CloseIcon from '@material-ui/icons/Close';

import { Transition } from 'react-transition-group';

class Projects extends React.Component{
  constructor(props){
    super(props);
    this.state={   
        
    };

  }


  componentDidMount(){

  }

  render() {
    return(
      <div style={{
        width:"100%",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, 120px)",
        columnGap:"70px",
        rowGap:"20px",
        // grid
        // gridAutoColumns:"auto",
        // gridAutoRows:"auto",
        // backgroundColor: "#2196F3",
        justifyContent:"center",
        justifyItems:"center",
        alignItems:"center",
        // padding: "10px",    
      }}
      >
        <ProjectTile 
        title={"Web App for Jet Listings"}
        backgroundImage={AvproLogoGold}
        backgroundImageSize={150}
        backgroundImagePositionX={11}
        backgroundImagePositionY={45}
        backgroundColor={"#262626"}
        onDetail={()=>this.props.onDetail(15)}
        />
        <ProjectTile 
        title={"Mobile App for Lost Pets"}
        backgroundImage={DogGoneLightBG}
        backgroundImageSize={150}
        backgroundImagePositionX={11}
        backgroundImagePositionY={15}
        backgroundColor={"#FFFFFF"}
        onDetail={()=>this.props.onDetail(14)}
        />
        <ProjectTile 
        title={"Virtual Reality App for Oculus"}
        backgroundImage={VuluskoLogo}
        backgroundImageSize={152}
        backgroundImagePositionX={12}
        backgroundImagePositionY={52}
        backgroundColor={"#232323"}
        onDetail={()=>this.props.onDetail(13)}
        />
        <ProjectTile
          title={"Custom POS and Order System"}
          backgroundImage={Sheds}
          backgroundImageSize={107}
          backgroundImagePositionX={32}
          backgroundImagePositionY={37}
          backgroundColor={"#273469"}
          onDetail={()=>this.props.onDetail(10)}
        />
        <ProjectTile
          title={"Real-time Stock Portfolio Tracker"}
          backgroundImage={TuliphedgeLogo}
          backgroundImageSize={148}
          backgroundImagePositionX={12}
          backgroundImagePositionY={-15}
          backgroundColor={"#1B3044"}
          onDetail={()=>this.props.onDetail(9)}
        />
        <ProjectTile
          title={"Multi-platform App for Studying"}
          backgroundImage={GrillmeLogo}
          backgroundImageSize={250}
          backgroundImagePositionX={-37}
          backgroundImagePositionY={-4}
          backgroundColor={"#6515E4"}
          onDetail={()=>this.props.onDetail(8)}
        />
        <ProjectTile
          title={"Lead Generating Alexa Skill"}
          backgroundImage={OptimalNetworks}
          backgroundImageSize={139}
          backgroundImagePositionX={10}
          backgroundImagePositionY={-4}
          backgroundColor={"#ffffff"}
          onDetail={()=>this.props.onDetail(7)}
        />
        <ProjectTile
          title={"Sine Wave Simulation"}
          backgroundImage={Sine}
          backgroundImageSize={200}
          backgroundImagePositionX={-21}
          backgroundImagePositionY={-22}
          backgroundColor={"#ffffff"}
          onDetail={()=>this.props.onDetail(6)}
        />
        <ProjectTile 
        title={"Mobile App Landing Page"}
        backgroundImage={UnionNewsLogo}
        backgroundImageSize={136}
        backgroundImagePositionX={18}
        backgroundImagePositionY={-1}
        backgroundColor={"#FFFFFF"}
        onDetail={()=>this.props.onDetail(12)}
        />
        <ProjectTile 
        title={"Kubernetes Cluster Migration"}
        backgroundImage={CallpopLogoWhite}
        backgroundImageSize={126}
        backgroundImagePositionX={22}
        backgroundImagePositionY={24}
        backgroundColor={"#f26522"}
        onDetail={()=>this.props.onDetail(11)}
        />
        <ProjectTile
          title={"Medical Ventilation Helper"}
          backgroundImage={BVM}
          backgroundImageSize={137}
          backgroundImagePositionX={15}
          backgroundImagePositionY={45}
          backgroundColor={"#ffffff"}
          onDetail={()=>this.props.onDetail(5)}
        />
        <ProjectTile
          title={"Novelty Alexa Skill"}
          backgroundImage={AlexaLogo}
          backgroundImageSize={138}
          backgroundImagePositionX={15}
          backgroundImagePositionY={27}
          backgroundColor={"#ffffff"}
          onDetail={()=>this.props.onDetail(4)}
        />
        <ProjectTile
          title={"Checkout Flow with Square API"}
          backgroundImage={SquareLogo}
          backgroundImageSize={138}
          backgroundImagePositionX={14}
          backgroundImagePositionY={-6}
          backgroundColor={"#000000"}
          onDetail={()=>this.props.onDetail(3)}
        />
        <ProjectTile
          title={"Stock Pitch Competition Platform"}
          backgroundImage={PitchCompetition}
          backgroundImageSize={111}
          backgroundImagePositionX={29}
          backgroundImagePositionY={30}
          backgroundColor={"#1B3044"}
          onDetail={()=>this.props.onDetail(2)}
        />
        <ProjectTile
          title={"Automated Email Labeling Tool"}
          backgroundImage={Gmail}
          backgroundImageSize={134}
          backgroundImagePositionX={11}
          backgroundImagePositionY={10}
          backgroundColor={"#ffffff"}
          onDetail={()=>this.props.onDetail(1)}
        />
        <ProjectTile
          title={"Finance Job Board and Application Tool"}
          backgroundImage={JobBoard}
          backgroundImageSize={159}
          backgroundImagePositionX={-2}
          backgroundImagePositionY={30}
          backgroundColor={"#28A745"}
          onDetail={()=>this.props.onDetail(0)}
        />
        {/* <ProjectTile 
        title={"Custom Admin Panel and API Server"}
        backgroundImage={InProgress}
        backgroundImageSize={184}
        backgroundImagePositionX={-2}
        backgroundImagePositionY={0}
        backgroundColor={"#1C0A3C"}
        inProgress={true}
        /> */}
      </div>
    );
  }

}

class ProjectTile extends React.Component{
  constructor(props){
    super(props);
    this.state={  
      hover:false,
      isMobile:window.innerWidth <= 760,
      selected:false,
      selectedAnimation:false,     
    };

    this.animationLength=1000;
  }


  componentDidMount(){

  }

  render() {
    return(
      <div style={{
        width:170,
        height:170,
        borderRadius:15,
        // display:"flex",
        // flexDirection:"column",
        // justifyContent:"flex-end",
        // alignItems:"center",
        cursor:`url(${PointCursor}), auto`,
        boxShadow:this.state.hover ? "0px 0px 10px 8px rgba(255, 255, 255, 0.8)":"0px 0px 5px 1px rgba(255, 255, 255, 0.8)",
        
        backgroundImage:`url(${this.props.backgroundImage})`,
        backgroundRepeat:"no-repeat",
        backgroundSize:this.props.backgroundImageSize,
        backgroundPosition:`${this.props.backgroundImagePositionX}px ${this.props.backgroundImagePositionY}px`,
        backgroundColor:this.props.backgroundColor,
        // transition:"100ms",

        // transform: this.state.hover ? "translate(-3px, -3px)":"translate(0px, 0px)",
        // padding:"10px",
        // margin:"10px",
      }}
      onMouseEnter={() => {
        if(!DeviceDetect.isMobileOrTablet()){
          this.setState({hover:true});
        }         
      }}
      onMouseLeave={() => this.setState({hover:false})}
      onClick={()=>{
        if(this.props.inProgress){
          return;
        }
        this.setState({
          selected:true,
          selectedAnimation:true,
        });
        this.props.onDetail();
        setTimeout(()=>this.setState({selectedAnimation:false}),this.animationLength);
      }}
      >
        <div style={{
          position:"relative",
          width:1,
          height:1,
          top:"50%",
          left:"50%",
          // backgroundColor:this.state.selectedAnimation ? "rgba(0,0,0, 1)":"rgba(255, 255, 255, 1)",
          opacity:this.state.selectedAnimation ? 0:1,
          backgroundColor:"rgba(255, 255, 255, 1)",
          transform:this.state.selectedAnimation ? "scale(3000,3000)":"scale(0,0)",
          transition:`${this.animationLength}ms`,
          pointerEvents:"none",
        }}>          
        </div>      
        <div style={{
          display:"flex",
          flexDirection:"column",
          justifyContent:"flex-end",
          alignItems:"center",
          width:"100%",
          height:"100%",

          borderRadius:15,
          backgroundColor:this.state.selectedAnimation ? "rgba(255, 255, 255, 1)":"transparent",
          transition:`background-color ${this.animationLength}`,
          // backgroundColor:"rgba(255, 255, 255, 1)",
        }}>

          <h4 style={{
            // width:"100%",
            border:"none",
            color:"white",
            textAlign:"center",
            backgroundColor:"rgba(0, 0, 0, 0.65)",
            borderRadius:1,
            boxShadow:"0px 0px 6px 6px rgba(0, 0, 0, 0.65)",
            margin:"10px 6px",
            fontFamily:Theme.secondaryFont,
            fontSize:14,
            opacity:this.state.selectedAnimation ? 0:1,
            transition:`opacity ${this.animationLength}`,
            
          }}>            
            {this.props.title}
          </h4>
        </div>
      </div>
    );
  }

}

class ProjectDetail extends React.Component{
  constructor(props){
    super(props);
    this.state={   
    };
    this.animationLength=500;
  }


  componentDidMount(){

  }

  render() {
    return(
      <Transition in={this.props.show} timeout={250}>
      {state => (
        <div style={{
          position:"fixed",
          top:0,
          left:0,
          width:"100vw",
          height:"100vh",          
          backgroundColor:DeviceDetect.isMobileOrTablet() ? "rgba(0,0,0,0.7)":"rgba(0,0,0,0.0)",
          zIndex:200,
          display:this.props.show ? "flex":"none",
          // display:"flex",
          justifyContent:"center",
          opacity:state==="entered" ? 1:0,
          //  ? "translateX(-2000px)":"translateX(0px)",
          transition:`opacity ${this.animationLength}ms`,          
        }}
        onClick={(e)=>{
          e.stopPropagation();
          this.props.onDetailClose();
        }}
        >
          <div style={{
            width:"100%",
            // maxWidth:"800px",
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            // backgroundColor:"green",
            color:"white",
            padding:"0px 5px",
          }}

          >
            <div style={{
              width:"100%",
              display:"flex",
              paddingRight:DeviceDetect.isMobileOrTablet() ? 10:60,
              paddingTop:10,
              // backgroundColor:"red",
              justifyContent:"flex-end",
            }}>
              <FloatingActionButton icon={<CloseIcon fontSize="large"/>}/>
            </div>
            <div style={{
              width:"100%",
              maxWidth:"800px",
              display:"flex",
              flexDirection:"column",
              // backgroundColor:"green", 
              height:"100%",             
              color:"white",
            }}
            onClick={(e)=>{
              e.stopPropagation();
            }}
            >
              <h1 style={{
                textAlign:"center",
                fontFamily:Theme.primaryFont,
                margin:DeviceDetect.isMobileOrTablet() ? "10px 0px":"10px 0px",
              }}>
                {this.props.title}
              </h1>
              <h4 style={{
                textAlign:"center",
                fontFamily:Theme.secondaryFont,  
                margin:DeviceDetect.isMobileOrTablet() ? "10px 0px":"20px 0px",         
              }}>
                {this.props.detail}
              </h4>
              {this.props.children}
            </div>
          </div>          
        </div>
      )}
    </Transition>
    );
  }

}

class ProjectImageZoom extends React.Component{
  constructor(props){
    super(props);
    this.state={   
        
    };

  }


  componentDidMount(){

  }

  render() {
    return(
      <div style={{
        position:"fixed",
        zIndex:500,
        top:0,
        backgroundColor:"rgba(0,0,0,1)",
        height:"100vh",
        width:"100vw",
        color:"white",
        opacity: this.props.show ? 1:0,
        pointerEvents: this.props.show ? "auto":"none",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
      }}
      onClick={this.props.onClose}
      >
            <div style={{
              position:"absolute",
              top:10,
              right:DeviceDetect.isMobileOrTablet() ? 10:60,
              // paddingRight:DeviceDetect.isMobileOrTablet() ? 10:60,
              // paddingTop:10,
              // width:"100%",
              // display:"flex",
              // paddingRight:DeviceDetect.isMobileOrTablet() ? 10:60,
              // paddingTop:10,
              // // backgroundColor:"red",
              // justifyContent:"flex-end",
            }}>
              <FloatingActionButton icon={<CloseIcon fontSize="large"/>}/>
            </div>
            <div style={{
              display:"block",
              width:"100vw",
              height:window.innerHeight-25,
              // backgroundColor:"red",
            }}>
              <img
              src={this.props.source}
              style={{
                objectFit: "contain",
                width:"100%",
                height:"100%",
              }}
              alt={"zoomed demo"}
              />
            </div>
      </div>
    );
  }

}

export {Projects, ProjectDetail,ProjectImageZoom};
