import React from 'react';
import '../App.css';
import Theme from '../theme';

import DeviceDetect from "../services/device.service";



class Overview extends React.Component{
  constructor(props){
    super(props);
    this.waveSpace=15;
    this.overviewString = "AIsoft is your on-demand CTO bringing your business cutting edge solutions built from the problem up";
    this.stringArray = this.overviewString.split("");   
    this.state={
        activeIndices:[],
        currAddIndex:0,
        currRemoveIndex:(0-this.waveSpace)%this.stringArray.length,
        increment:1,
    };
 

  }

  componentDidMount(){
    setInterval(()=>{
      this.setState({
        activeIndices:[...this.state.activeIndices.filter((index)=>(index!==this.state.currRemoveIndex)),this.state.currAddIndex],
        currAddIndex:(this.state.currAddIndex+this.state.increment)%this.stringArray.length,
        currRemoveIndex:(this.state.currRemoveIndex+this.state.increment)%this.stringArray.length,
      });
    },75);
  }

  render() {
    
    let elements = this.stringArray.map((char,index)=>{
      if(index===28 || index===64){
        return (
          <React.Fragment key={index}>
            <span 
            // key={index}
            style={{
              height:40,
              paddingLeft:char === " " ? 5:0,
              textShadow:this.state.activeIndices.includes(index) ? "0px 0px 4px rgba(255, 255, 255, 0.8)":"none",
              transform:this.state.activeIndices.includes(index)? `scale(1.1,1.1) translateY(${DeviceDetect.isMobileOrTablet() ? '-2px':'-5px'})`:"scale(1,1) translateY(0px)",         
              transition:"500ms",
            }}>
              {char}
            </span>
          <div 
          // key={index*200}
          style={{
            flexGrow:1,
            width:"100%",
          }}></div>
          </React.Fragment>
        );
      }else{
       return (<span 
        key={index}
        style={{
          height:40,
          paddingLeft:char === " " ? 5:0,
          textShadow:this.state.activeIndices.includes(index) ? "0px 0px 4px rgba(255, 255, 255, 0.8)":"none",
          transform:this.state.activeIndices.includes(index)? `scale(1.1,1.1) translateY(${DeviceDetect.isMobileOrTablet() ? '-2px':'-5px'})`:"scale(1,1) translateY(0px)",        
          transition:"500ms",
        }}>
          {char}
        </span>);
      }

    });
    return(
      <div style={{
        display:"flex",
        flexWrap:"wrap",
        width:"100%",
        color:"white",
        fontSize:DeviceDetect.isMobileOrTablet() ? 18:35,
        fontFamily:Theme.secondaryFont,        
      }}
      >
        {elements}
          
      </div> 
    );
  }

}

export default Overview;
