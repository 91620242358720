import React from 'react';
import '../App.css';
import Theme from '../theme';

import SampleMobileApp from "../assets/img/sample_app.png";
import SampleWebApp from "../assets/img/sample_web_app.png";
import Alexa from "../assets/img/alexa.png";
import Oculus from "../assets/img/oculus.png";
import Server from "../assets/img/server.png";

import DeviceDetect from "../services/device.service";


class Services extends React.Component{
  constructor(props){
    super(props);
    this.state={   
        
    };

  }

  componentDidMount(){

  }

  render() {
    let spacing = DeviceDetect.isMobileOrTablet() ? 40:100;
    return(
      <div style={{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        color:"white",
        fontFamily:Theme.secondaryFont,      
      }}
      >
        {/* <div style={{
          marginTop:spacing,
        }}></div> */}
        <ServiceTile
        image={SampleMobileApp}
        title={"Mobile Apps"}
        description={`
        Cross-platform, iOS and Android mobile apps 
        built with Flutter to grow or launch your business. Need GPS, 
        notifications, photos, video, bluetooth and chat? We've got you covered.
        `}
        reverse={true}
        width={200}
        height={403}
        />
        <div style={{
          marginTop:spacing,
        }}></div>
        <ServiceTile
        image={SampleWebApp}
        title={"Web Apps"}
        description={`
        Web apps built with React to deliver powerful, custom workflows. 
        Need to integrate with email, a CRM, Docusign, text messaging, 
        a mobile app, your own databases or something else? We'll handle it.
        `}
        reverse={false}
        width={DeviceDetect.isMobileOrTablet() ? 350:450}
        height={DeviceDetect.isMobileOrTablet() ? 200:257}
        />
        <div style={{
          marginTop:spacing,
        }}></div>
        <ServiceTile
        image={Oculus}
        title={"VR Apps"}
        description={`
        VR experiences to enchant your customers or enhance your internal training. 
        Need to deploy to Oculus, Vive, Valve or the web? We're ready to go.
        `}
        reverse={true}
        width={DeviceDetect.isMobileOrTablet() ? 350:450}
        height={DeviceDetect.isMobileOrTablet() ? 231:297}
        />
        <div style={{
          marginTop:spacing,
        }}></div>
        <ServiceTile
        image={Alexa}
        title={"Voice Apps"}
        description={`
        Alexa Skills and Google Home Apps to engage your customers audibly or provide a
        hands-free interface for your team. Need a voice app that can place orders, send a notification and more? We can do that.
        `}
        reverse={false}
        width={300}
        height={300}
        />
        <div style={{
          marginTop:spacing,
        }}></div>
        <ServiceTile
        image={Server}
        title={"Integrations"}
        description={`
        Endless API integrations and cloud deployments for your business. Need your website to trigger an email 
        which sends a Docusign and a text message based on data in your CRM? If you can think of it, we can create it.
        `}
        reverse={true}
        width={257}
        height={403}
        />


      </div> 
    );
  }

}

class ServiceTile extends React.Component{
  constructor(props){
    super(props);
    this.state={   
        
    };

  }

  componentDidMount(){

  }

  render() {
    return(
      <div style={{
        display:"flex",
        width:"100%",
        flexDirection:DeviceDetect.isMobileOrTablet() ? "column-reverse":(this.props.reverse ? "row-reverse":"row"),
      }}
      >
        <img 
        style={{
          flexShrink:0,
          marginBottom:20,
          alignSelf:DeviceDetect.isMobileOrTablet() ? "center":"start",
        }}
        src={this.props.image} 
        alt={"Sample App"}
        width={this.props.width}
        height={this.props.height}
        
        />
        <div style={{
          margin:20,
        }}>

        </div>
        <div style={{
          display:"flex",
          flexDirection:"column",
          marginLeft:20,
          flexGrow:1,
          color:"white",
          flexWrap:"wrap",
        }}>
          <h1 style={{
              fontSize:40,
              margin:0,
              color:"white",
              fontFamily:Theme.primaryFont, 
          }}>
            {this.props.title}
          </h1>
          <div style={{
            marginTop:20,
            fontSize:20,
            color:"white",
            fontFamily:Theme.secondaryFont,  
          }}>
            {this.props.description}
          </div>

        </div>

      </div> 
    );
  }

}

export default Services;
