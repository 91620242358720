import React from 'react';
import './App.css';
import Logo from "./assets/img/logo_long_dark_bg.png";
import Tuliphedge1 from "./assets/img/screenshot-portfolio.png";
import Tuliphedge2 from "./assets/img/screenshot-performance.png";
import Tuliphedge3 from "./assets/img/screenshot-transactions.png";

import Tuliphedge4 from "./assets/img/screenshot-competition-main-page.png";
import Tuliphedge5 from "./assets/img/screenshot-competition-submission-main-page.png";
import Tuliphedge6 from "./assets/img/screenshot-reviews.png";
import Tuliphedge7 from "./assets/img/screenshot-submission-main-page.png";

import Tuliphedge8 from "./assets/img/screenshot-job-listing.png";
import Tuliphedge9 from "./assets/img/screenshot-job-post.png";

import Grillme1 from "./assets/img/grillme_home.png";
import Grillme2 from "./assets/img/grillme_quiz.png";
import Grillme3 from "./assets/img/grillme_qa_show.png";
import Grillme4 from "./assets/img/grillme_launcher_logo.png";
import Grillme5 from "./assets/img/grillme_home_add_quiz.png";
import Grillme6 from "./assets/img/grillme_home_edit_quiz.png";

import SineImage from "./assets/img/sine-explorer-detail.png";

import SquareLogo from "./assets/img/square_logo_transparent.png";
import Cursor from "./assets/img/cursor.png";
import PointCursor from "./assets/img/point_cursor.png";

import Theme from './theme';
import Graph from './components/graph.component';
import Menu from './components/menu.component';
import Team from './components/team.component';
import Contact from './components/contact.component';
import Services from './components/services.component';
import Process from './components/process.component';
import Overview from './components/overview.component';
import Footer from './components/footer.component';
import {Projects,ProjectDetail, ProjectImageZoom} from './components/projects.component';
import {
  PrimaryButton, 
  FloatingActionButton,
  LinkBadge,
} from "./components/buttons.component";
import DeviceDetect from "./services/device.service";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import HistoryIcon from '@material-ui/icons/History';
import GroupIcon from '@material-ui/icons/Group';
import EmailIcon from '@material-ui/icons/Email';
import SettingsIcon from '@material-ui/icons/Settings';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import LandscapeIcon from '@material-ui/icons/Landscape';

import { Transition } from 'react-transition-group';

// https://www.npmjs.com/package/react-material-ui-carousel
import Carousel from 'react-material-ui-carousel'

class App extends React.Component{
  constructor(props){
    super(props);
    this.state={
      signupHover:false,
      mousePositionX:0,
      mousePositionY:0,
      scrollPostion:0,
      showMenu:false,
      firstAppear:false,
      buttonLongPress:false,
      hideMain:false,
      hideMenuButton:false,    
      projectDetailIndex:null,
      showImageZoom:false,
      zoomImageSource:null,
      zoomImageWidth:0,
      zoomImageHeight:0,
    };

    this.listenToScroll = this.listenToScroll.bind(this);

    this.topRef = React.createRef();
    this.overviewRef = React.createRef();
    this.projectsRef = React.createRef();
    this.teamRef = React.createRef();
    this.contactRef = React.createRef();
    this.servicesRef = React.createRef();
    this.processRef = React.createRef();

    
  }

  componentDidMount() {
    setTimeout(()=>this.setState({firstAppear:true}),20);
    window.addEventListener('scroll', this.listenToScroll);
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  
    // const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  
    // const scrolled = winScroll / height;
  
    this.setState({
      scrollPostion:winScroll,
    })
  }

  render() {
    let maxOpacity=0.25;
    let contentY = 200;
    // let percentOpacity = this.state.scrollPostion/contentY;
    let scrollFirstPercent = this.state.scrollPostion/contentY;
    let animationOpacity = Math.max(1-scrollFirstPercent*(maxOpacity),maxOpacity);
    if(this.state.hideMain){
      animationOpacity=1;
    }

    let buttonScrollCutoffY = 20;
    let buttonScrollCuttofPercent = this.state.scrollPostion/buttonScrollCutoffY;
    let buttonOpacity = Math.max(1-buttonScrollCuttofPercent*(0.05),0.05);
    if(buttonOpacity<0.1){
      buttonOpacity=0;
    }

    let maxRotation=180;
    let actionButtonRotation = Math.min(maxRotation*scrollFirstPercent,maxRotation);

    let scrollPercent = this.state.scrollPostion/(document.documentElement.scrollHeight - document.documentElement.clientHeight) || 0;

    let tuliphedgeWidth=Math.min(window.innerWidth,550);
    let tuliphedgeHeight=tuliphedgeWidth/1.567073171;

    let grillmeWidth=Math.min(150,800);
    let grillmeHeight=grillmeWidth*1.778666667;

    let sineWidth=Math.min(600);
    let sineHeight=sineWidth/2.018675722;

    let tuliphedge2Width=Math.min(window.innerWidth,400);
    let tuliphedge2Height=tuliphedge2Width/1.029094828;

    let tuliphedge3Width=Math.min(window.innerWidth,400);
    let tuliphedge3Height=tuliphedge3Width/1.070786517;

    return(
      <Transition in={this.state.firstAppear} timeout={0}>
        {state => (      
          <div style={{   
            opacity:state==="entered" ? 1:0,
            transition: "1000ms",   
            minHeight:"100%",
            width:"100%",
            backgroundColor:Theme.color1,
            // display:"flex",
            // flexDirection:"column",
            // alignItems:"center",
            position: "absolute",
            // overflow:"auto",
            overflow: "hidden",
            flexGrow:1,
            zIndex:11,
            cursor:`url(${Cursor}), auto`,
          }}
          onMouseMove={(e)=>{
            this.setState({mousePositionX:e.clientX,mousePositionY:e.clientY});
          }}
          ref={this.topRef}
          
          >
            <div style={{
              // marginTop:10,
              // position:"sticky",
              // top:0,
              position:"fixed",
              top:10,
              right:10,
              zIndex:100,
            }}>
              {!this.state.hideMenuButton &&
                <FloatingActionButton 
                icon={this.state.showMenu ? <CloseIcon fontSize="large"/>:<MenuIcon fontSize="large"/>} 
                rotate={0} 
                border={false}
                onClick={()=>this.setState({
                  showMenu:!this.state.showMenu,
                  hideMain:!this.state.hideMain,              
                })}
                />
              }
            </div>
            {!this.state.hideMain && 
              <div style={{
                // marginTop:10,
                // position:"sticky",
                // top:0,
                position:"fixed",
                bottom:25,
                right:25,
                zIndex:100,
              }}>
                <FloatingActionButton 
                icon={<ExpandMoreIcon fontSize="large"/>} 
                rotate={actionButtonRotation} 
                border={true}
                onClick={()=>{
                  if(actionButtonRotation>=180){
                    // window.scrollTo(0, this.myRef.current.offsetTop); 
                    this.topRef.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                      inline: 'start',
                    });
                  }else{
                    this.overviewRef.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                      inline: 'start',
                    });
                  }
                }}
                />
              </div>
            }
            <Menu 
              show={this.state.showMenu}
              topRef={this.topRef}
              overviewRef={this.overviewRef}
              projectsRef={this.projectsRef}
              teamRef={this.teamRef}
              contactRef={this.contactRef}
              servicesRef={this.servicesRef}
              processRef={this.processRef}
              closeFunction={()=>this.setState({showMenu:false, hideMain:false})}     
            />

            <Graph 
            mousePositionX={this.state.mousePositionX} 
            mousePositionY={this.state.mousePositionY} 
            opacity={animationOpacity} 
            isMobile={window.innerWidth <= 760}
            scrollPercentage={scrollPercent}
            buttonLongPress={this.state.buttonLongPress}   
            />

            <ProjectImageZoom
              show={this.state.showImageZoom}
              source={this.state.zoomImageSource}
              onClose={()=>this.setState({showImageZoom:false})}
              width={this.state.zoomImageWidth}
              height={this.state.zoomImageHeight}
            />

            <ProjectDetail
              onDetailClose={()=>{
                this.setState({
                  projectDetailIndex:null,
                  hideMain:false,
                  hideMenuButton:false,
                });
              }}
              show={this.state.projectDetailIndex===15}
              detail={`
              Created a custom API and admin panel to manage jet listings. Original design and UI by Brave UX.
              `}
              title={"Web App for Jet Listings"}
            >
              <div style={{
                width:"100%",
                display:"flex",
                justifyContent:"center",
                marginBottom:20,
              }}>
                  <LinkBadge 
                  variant={"aisoft"}
                  url={"https://avprojets.com/"}
                  />
              </div> 
              <iframe 
              title={"Demo Video"}
              width={"100%"} 
              height={350}
              src={"https://www.youtube.com/embed/uIQFSFKT9_0"}
              frameBorder="0" 
              rel="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen={true}
              >
              </iframe>     
            </ProjectDetail>
            
            <ProjectDetail
              onDetailClose={()=>{
                this.setState({
                  projectDetailIndex:null,
                  hideMain:false,
                  hideMenuButton:false,
                });
              }}
              show={this.state.projectDetailIndex===14}
              detail={`
              This mobile app allows users to report lost or found pets in Park City 
              with GPS, pictures and report notifications.         
              `}
              title={"Mobile App for Lost Pets"}
            >
              <div style={{
                width:"100%",
                display:"flex",
                justifyContent:"space-around",
                flexWrap:"wrap",
                marginBottom:20,
              }}>
                <div style={{margin:"10px 10px"}}>
                  <LinkBadge 
                  variant={"aisoft"}
                  url={"https://dog-gone.org/"}
                  />
                </div>
                <div style={{margin:"10px 10px"}}>
                  <LinkBadge 
                  variant={"apple"}
                  url={"https://apps.apple.com/us/app/id1519637537"}
                  />
                </div>
                <div style={{margin:"10px 10px"}}>
                  <LinkBadge 
                  variant={"google"}
                  url={"https://play.google.com/store/apps/details?id=dev.aisoft.lost_dog_app"}
                  />
                </div>
              </div>
              <iframe 
              title={"Demo Video"}
              width={"100%"} 
              height={350}
              src={"https://www.youtube.com/embed/yg_pPazsSW8"}
              frameBorder="0" 
              rel="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen={true}
              >
              </iframe>      
            </ProjectDetail>

            <ProjectDetail
              onDetailClose={()=>{
                this.setState({
                  projectDetailIndex:null,
                  hideMain:false,
                  hideMenuButton:false,
                });
              }}
              show={this.state.projectDetailIndex===13}
              detail={`
              A landing page and a VR app for corporate presentations built with Unity.
              `}
              title={"Virtual Reality App for Oculus"}
            >
              <div style={{
                width:"100%",
                display:"flex",
                justifyContent:"center",
                marginBottom:20,
              }}>
                  <LinkBadge 
                  variant={"aisoft"}
                  url={"https://vulusko.com/"}
                  />
              </div>      
            </ProjectDetail>

            <ProjectDetail
              onDetailClose={()=>{
                this.setState({
                  projectDetailIndex:null,
                  hideMain:false,
                  hideMenuButton:false,
                });
              }}
              show={this.state.projectDetailIndex===12}
              detail={`
              A landing page for a mobile app that helps users find factual news.
              `}
              title={"Mobile App Landing Page"}
            >
              <div style={{
                width:"100%",
                display:"flex",
                justifyContent:"center",
                marginBottom:20,
              }}>
                  <LinkBadge 
                  variant={"aisoft"}
                  url={"https://union-news.us/"}
                  />
              </div> 
              <iframe 
              title={"Demo Video"}
              width={"100%"} 
              height={350}
              src={"https://www.youtube.com/embed/LVTLQJfuhLA"}
              frameBorder="0" 
              rel="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen={true}
              >
              </iframe>     
            </ProjectDetail>

            <ProjectDetail
              onDetailClose={()=>{
                this.setState({
                  projectDetailIndex:null,
                  hideMain:false,
                  hideMenuButton:false,
                });
              }}
              show={this.state.projectDetailIndex===11}
              detail={`
              We helped Callpop migrate their cloud services from a single server
              to a multi-server Kubernetes cluster to allow for better scalability and redundancy.
              `}
              title={"Kubernetes Cluster Migration"}
            >
              <div style={{
                width:"100%",
                display:"flex",
                justifyContent:"center",
                marginBottom:20,
              }}>
                  <LinkBadge 
                  variant={"aisoft"}
                  url={"https://www.callpop.com/"}
                  />
              </div>     
            </ProjectDetail>

            <ProjectDetail
              onDetailClose={()=>{
                this.setState({
                  projectDetailIndex:null,
                  hideMain:false,
                  hideMenuButton:false,
                });
              }}
              show={this.state.projectDetailIndex===10} 
              detail={`
              This web app allows for placing and managing orders from multiple retail locations. 
              It handles user management, payment calculation, accepting payment and emailing 
              pre-filled docusign documents to the customer for signature.
              `}
              title={"Custom POS and Order System"}
            >
              <iframe 
              title={"Demo Video"}
              width={"100%"} 
              height={350}
              src={"https://www.youtube.com/embed/BIMP78Pw5aI"}
              frameBorder="0" 
              rel="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen={true}
              >
              </iframe>      
            </ProjectDetail>

            <ProjectDetail
              onDetailClose={()=>{
                this.setState({
                  projectDetailIndex:null,
                  hideMain:false,
                  hideMenuButton:false,
                });
              }}
              show={this.state.projectDetailIndex===9} 
              detail={`
              A tool that allows groups to collectively track a 
              stock portfolio and perform detailed historical performance analysis.
              `}
              title={"Real-time Stock Portfolio Tracker"}
            >
              <div style={{
                width:"100%",
                display:"flex",
                justifyContent:"center",
              }}>
                <Carousel
                  style={{
                    cursor:`url(${PointCursor}), auto`,
                  }}
                  className={"carousel"}
                  navButtonsAlwaysVisible={true}
                  animation={"fade"}
                  timeout={500}
                >

                  <img src={Tuliphedge1} alt={"Tuliphedge Example Graphic"} 
                  width={`${tuliphedgeWidth}px`}
                  height={`${tuliphedgeHeight}px`}
                  onClick={()=>this.setState({
                    showImageZoom:true,
                    zoomImageSource:Tuliphedge1,
                    zoomImageWidth:tuliphedgeWidth,
                    zoomImageHeight:tuliphedgeHeight,
                  })}
                    />

                  <img src={Tuliphedge2} alt={"Tuliphedge Example Graphic"}
                  width={`${tuliphedgeWidth}px`}
                  height={`${tuliphedgeHeight}px`}
                  onClick={()=>this.setState({
                    showImageZoom:true,
                    zoomImageSource:Tuliphedge2,
                    zoomImageWidth:tuliphedgeWidth,
                    zoomImageHeight:tuliphedgeHeight,
                  })}
                  />

                  <img src={Tuliphedge3} alt={"Tuliphedge Example Graphic"}
                  width={`${tuliphedgeWidth}px`}
                  height={`${tuliphedgeHeight}px`}
                  onClick={()=>this.setState({
                    showImageZoom:true,
                    zoomImageSource:Tuliphedge3,
                    zoomImageWidth:tuliphedgeWidth,
                    zoomImageHeight:tuliphedgeHeight,
                  })}
                  />
                </Carousel>
              </div>
            </ProjectDetail>

            <ProjectDetail
              onDetailClose={()=>{
                this.setState({
                  projectDetailIndex:null,
                  hideMain:false,
                  hideMenuButton:false,
                });
              }}
              show={this.state.projectDetailIndex===8} 
              detail={`
              An app on iPhone, Android, Web, Google Home and Alexa that allows 
              users to create quizzes and have their voice assistant quiz them.
              `}
              title={"Multi-platform App for Studying"}
            >
              <div style={{
                width:"100%",
                display:"flex",
                justifyContent:"space-around",
                flexWrap:"wrap",
                marginBottom:20,
              }}>
                <div style={{margin:"10px 10px"}}>
                  <LinkBadge 
                  variant={"aisoft"}
                  url={"https://grillme.dev/?ref=aisoft"}
                  />
                </div>
                <div style={{margin:"10px 10px"}}>
                  <LinkBadge 
                  variant={"apple"}
                  url={"https://itunes.apple.com/us/app/grillme/id1496972160?ls=1"}
                  />
                </div>
                <div style={{margin:"10px 10px"}}>
                  <LinkBadge 
                  variant={"google"}
                  url={"https://play.google.com/store/apps/details?id=dev.aisoft.grillme&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"}
                  />
                </div>
              </div>
              <iframe 
              title={"Demo Video"}
              width={"100%"} 
              height={350}
              src={"https://www.youtube.com/embed/YVqwkllPEGM"}
              frameBorder="0" 
              rel="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen={true}
              >
              </iframe>  
              {/* <div style={{
                width:"100%",
                display:"flex",
                justifyContent:"center",
              }}>
                {!DeviceDetect.isMobile() &&
                  <Carousel
                    navButtonsAlwaysVisible={false}
                    animation={"fade"}
                    timeout={500}
                  >
                    
                    <img src={Grillme1} alt={"Grillme Example Graphic"} 
                    width={`${grillmeWidth}px`}
                    height={`${grillmeHeight}px`}
                    onClick={()=>this.setState({
                    showImageZoom:true,
                    zoomImageSource:Grillme1,
                    zoomImageWidth:grillmeWidth,
                    zoomImageHeight:grillmeHeight,
                  })}
                      />
                    <img src={Grillme2} alt={"Grillme Example Graphic"} 
                    width={`${grillmeWidth}px`}
                    height={`${grillmeHeight}px`}
                    onClick={()=>this.setState({
                    showImageZoom:true,
                    zoomImageSource:Grillme2,
                    zoomImageWidth:grillmeWidth,
                    zoomImageHeight:grillmeHeight,
                  })}
                      />
                    <img src={Grillme3} alt={"Grillme Example Graphic"} 
                    width={`${grillmeWidth}px`}
                    height={`${grillmeHeight}px`}
                    onClick={()=>this.setState({
                    showImageZoom:true,
                    zoomImageSource:Grillme3,
                    zoomImageWidth:grillmeWidth,
                    zoomImageHeight:grillmeHeight,
                  })}
                      />
                    <img src={Grillme4} alt={"Grillme Example Graphic"} 
                    width={`${grillmeWidth}px`}
                    height={`${grillmeHeight}px`}
                    onClick={()=>this.setState({
                    showImageZoom:true,
                    zoomImageSource:Grillme4,
                    zoomImageWidth:grillmeWidth,
                    zoomImageHeight:grillmeHeight,
                  })}
                      />
                    <img src={Grillme5} alt={"Grillme Example Graphic"} 
                    width={`${grillmeWidth}px`}
                    height={`${grillmeHeight}px`}
                    onClick={()=>this.setState({
                    showImageZoom:true,
                    zoomImageSource:Grillme5,
                    zoomImageWidth:grillmeWidth,
                    zoomImageHeight:grillmeHeight,
                  })}
                      />
                    <img src={Grillme6} alt={"Grillme Example Graphic"} 
                    width={`${grillmeWidth}px`}
                    height={`${grillmeHeight}px`}
                    onClick={()=>this.setState({
                    showImageZoom:true,
                    zoomImageSource:Grillme6,
                    zoomImageWidth:grillmeWidth,
                    zoomImageHeight:grillmeHeight,
                    })}
                      />

                  </Carousel>
                }

              </div> */}
            </ProjectDetail>

            <ProjectDetail
              onDetailClose={()=>{
                this.setState({
                  projectDetailIndex:null,
                  hideMain:false,
                  hideMenuButton:false,
                });
              }}
              show={this.state.projectDetailIndex===7} 
              detail={`
              This Skill introduces the user to 
              the various services of the company and allows them to share their contact information for a follow up.
              `}
              title={"Lead Generating Alexa Skill"}
            >
              <div style={{
                width:"100%",
                display:"flex",
                justifyContent:"center",
                marginBottom:20,
              }}>
                  <LinkBadge 
                  variant={"alexa"}
                  url={"https://skills-store.amazon.com/deeplink/dp/B0867W3LBJ?deviceType=app&share&refSuffix=ss_copy"}
                  />
              </div>
              <iframe 
              title={"Demo Video"}
              width={"100%"} 
              height={300}
              src={"https://www.youtube.com/embed/Yfw1NXKkLe8"}
              frameBorder="0" 
              rel="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen={true}
              >
              </iframe> 

            </ProjectDetail>

            <ProjectDetail
              onDetailClose={()=>{
                this.setState({
                  projectDetailIndex:null,
                  hideMain:false,
                  hideMenuButton:false,
                });
              }}
              show={this.state.projectDetailIndex===6} 
              detail={`
              An educational simulation to demonstrate the impact of 
              a sine wave function on a boat's movement.
              `}
              title={"Sine Wave Simulation"}
            >
              <div style={{
                width:"100%",
                display:"flex",
                justifyContent:"center",
                marginBottom:20,
              }}>
                  <LinkBadge 
                  variant={"aisoft"}
                  url={"https://sine.aisoft.dev/"}
                  />
              </div>
              <div style={{
                width:"100%",
                display:"flex",
                justifyContent:"center",
              }}>
                <Carousel
                  navButtonsAlwaysVisible={false}
                  animation={"fade"}
                  timeout={500}
                >

                  <img src={SineImage} alt={"Sine Explorer Example Graphic"} 
                  width={`${sineWidth}px`}
                  height={`${sineHeight}px`}
                  onClick={()=>this.setState({
                    showImageZoom:true,
                    zoomImageSource:SineImage,
                    zoomImageWidth:sineWidth,
                    zoomImageHeight:sineHeight,
                  })}
                    />
                </Carousel>
              </div>
            </ProjectDetail>

            <ProjectDetail
              onDetailClose={()=>{
                this.setState({
                  projectDetailIndex:null,
                  hideMain:false,
                  hideMenuButton:false,
                });
              }}
              show={this.state.projectDetailIndex===5} 
              detail={`
              A simple tool to help emergency medical 
              professionals keep track of ventilation rates.
              `}
              title={"Medical Ventilation Helper"}
            >
              <div style={{
                width:"100%",
                display:"flex",
                justifyContent:"center",
                marginBottom:20,
              }}>
                  <LinkBadge 
                  variant={"aisoft"}
                  url={"https://bvmrate.com/"}
                  />
              </div>
              <iframe 
              title={"Demo Video"}
              width={"100%"} 
              height={300}
              src={"https://www.youtube.com/embed/mmDe0J-rD4s"}
              frameBorder="0" 
              rel="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen={true}
              >
              </iframe> 

            </ProjectDetail>

            <ProjectDetail
              onDetailClose={()=>{
                this.setState({
                  projectDetailIndex:null,
                  hideMain:false,
                  hideMenuButton:false,
                });
              }}
              show={this.state.projectDetailIndex===4} 
              detail={`
              An Alexa skill that plays 99 Bottles of Beer in her voice.
              `}
              title={"Novelty Alexa Skill"}
            >
              <div style={{
                width:"100%",
                display:"flex",
                justifyContent:"center",
                marginBottom:20,
              }}>
                  <LinkBadge 
                  variant={"alexa"}
                  url={"https://skills-store.amazon.com/deeplink/dp/B07D6VQMTD?deviceType=app&share&refSuffix=ss_copy"}
                  />
              </div>
              <iframe 
              title={"Demo Video"}
              width={"100%"} 
              height={300}
              src={"https://www.youtube.com/embed/anP9SPlTuos"}
              frameBorder="0" 
              rel="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen={true}
              >
              </iframe> 

            </ProjectDetail>

            <ProjectDetail
              onDetailClose={()=>{
                this.setState({
                  projectDetailIndex:null,
                  hideMain:false,
                  hideMenuButton:false,
                });
              }}
              show={this.state.projectDetailIndex===3} 
              detail={`
              A custom shopping cart and checkout flow that integrates with Square via an API server on Google Cloud.
              `}
              title={"Checkout Flow with Square API"}
            >
              <iframe 
              title={"Demo Video"}
              width={"100%"} 
              height={350}
              src={"https://www.youtube.com/embed/0tTG1GDEujE"}
              frameBorder="0" 
              rel="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen={true}
              >
              </iframe> 

            </ProjectDetail>

            <ProjectDetail
              onDetailClose={()=>{
                this.setState({
                  projectDetailIndex:null,
                  hideMain:false,
                  hideMenuButton:false,
                });
              }}
              show={this.state.projectDetailIndex===2} 
              detail={`
              Platform allowing school groups to compete by submitting presentation 
              videos and documents, allowing users to browse and review other submissions.
              `}
              title={"Stock Pitch Competition Platform"}
            >
              <div style={{
                width:"100%",
                display:"flex",
                justifyContent:"center",
              }}>
                <Carousel
                  style={{
                    cursor:`url(${PointCursor}), auto`,
                  }}
                  className={"carousel"}
                  navButtonsAlwaysVisible={true}
                  animation={"fade"}
                  timeout={500}
                >

                  <img src={Tuliphedge4} alt={"Tuliphedge Example Graphic"} 
                  width={`${tuliphedge2Width}px`}
                  height={`${tuliphedge2Height}px`}
                  onClick={()=>this.setState({
                    showImageZoom:true,
                    zoomImageSource:Tuliphedge4,
                    zoomImageWidth:tuliphedge2Width,
                    zoomImageHeight:tuliphedge2Height,
                  })}
                    />

                  <img src={Tuliphedge5} alt={"Tuliphedge Example Graphic"}
                  width={`${tuliphedge2Width}px`}
                  height={`${tuliphedge2Height}px`}
                  onClick={()=>this.setState({
                    showImageZoom:true,
                    zoomImageSource:Tuliphedge5,
                    zoomImageWidth:tuliphedge2Width,
                    zoomImageHeight:tuliphedge2Height,
                  })}
                  />

                  <img src={Tuliphedge6} alt={"Tuliphedge Example Graphic"}
                  width={`${tuliphedge2Width}px`}
                  height={`${tuliphedge2Height}px`}
                  onClick={()=>this.setState({
                    showImageZoom:true,
                    zoomImageSource:Tuliphedge6,
                    zoomImageWidth:tuliphedge2Width,
                    zoomImageHeight:tuliphedge2Height,
                  })}
                  />

                  <img src={Tuliphedge7} alt={"Tuliphedge Example Graphic"}
                  width={`${tuliphedge2Width}px`}
                  height={`${tuliphedge2Height}px`}
                  onClick={()=>this.setState({
                    showImageZoom:true,
                    zoomImageSource:Tuliphedge7,
                    zoomImageWidth:tuliphedge2Width,
                    zoomImageHeight:tuliphedge2Height,
                  })}
                  />
                </Carousel>
              </div>
            </ProjectDetail>


            <ProjectDetail
              onDetailClose={()=>{
                this.setState({
                  projectDetailIndex:null,
                  hideMain:false,
                  hideMenuButton:false,
                });
              }}
              show={this.state.projectDetailIndex===1} 
              detail={`
              A tool that saves our client time, by allowing Gmail labels to synchronize with a changing list of 1,000+ emails.
              `}
              title={"Automated Email Labeling Tool"}
            >
              <iframe 
              title={"Demo Video"}
              width={"100%"} 
              height={350}
              src={"https://www.youtube.com/embed/S9FzKe5mTT0"}
              frameBorder="0" 
              rel="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen={true}
              >
              </iframe> 

            </ProjectDetail>


            <ProjectDetail
              onDetailClose={()=>{
                this.setState({
                  projectDetailIndex:null,
                  hideMain:false,
                  hideMenuButton:false,
                });
              }}
              show={this.state.projectDetailIndex===0} 
              detail={`
              A tool allowing candidates to search and filter job opportunities and allow candidates to apply on the platform.              
              `}
              title={"Finance Job Board and Application Tool"}
            >
              <div style={{
                width:"100%",
                display:"flex",
                justifyContent:"center",
              }}>
                <Carousel
                  style={{
                    cursor:`url(${PointCursor}), auto`,
                  }}
                  className={"carousel"}
                  navButtonsAlwaysVisible={true}
                  animation={"fade"}
                  timeout={500}
                >

                  <img src={Tuliphedge8} alt={"Tuliphedge Example Graphic"} 
                  width={`${tuliphedge3Width}px`}
                  height={`${tuliphedge3Height}px`}
                  onClick={()=>this.setState({
                    showImageZoom:true,
                    zoomImageSource:Tuliphedge8,
                    zoomImageWidth:tuliphedge3Width,
                    zoomImageHeight:tuliphedge3Height,
                  })}
                    />

                  <img src={Tuliphedge9} alt={"Tuliphedge Example Graphic"}
                  width={`${tuliphedge3Width}px`}
                  height={`${tuliphedge3Height}px`}
                  onClick={()=>this.setState({
                    showImageZoom:true,
                    zoomImageSource:Tuliphedge9,
                    zoomImageWidth:tuliphedge3Width,
                    zoomImageHeight:tuliphedge3Height,
                  })}
                  />

                </Carousel>
              </div>
            </ProjectDetail>

            <div style={{
                width:"100%",
                display:"flex",
                justifyContent:"center",
              }}>
            
            <div style={{      
            minHeight:"100%",
            width:"100%",
            // backgroundColor:Theme.color1,
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            maxWidth:800,
            padding:"0px 5px",
            // position: "absolute",
            // overflow:"auto",
            // flexGrow:1,
            zIndex:9,
            // cursor:`url(${Cursor}), auto`,
            opacity:this.state.hideMain ? 0:1,
            transition:"opacity 750ms",            
          }}
          
          >
              <div style={{
                margin:"10px 0px 0px 0px",
              }}
              
              >
                <Transition in={this.state.firstAppear} timeout={500}>
                  {state => (
                    <div style={{
                      opacity:state==="entered" ? 1:0,
                      transition: "1000ms",
                    }}>
                      {/* 400/136 */}
                        <img src={Logo} alt="AIsoft Logo" width={DeviceDetect.isMobileOrTablet() ? 200:250} height={DeviceDetect.isMobileOrTablet() ? 68:85}/>
                    </div>
                  )}
                </Transition>
                
              </div>
              <Transition in={this.state.firstAppear} timeout={1000}>
                  {state => (
                    <div style={{
                      opacity:state==="entered" ? 1:0,
                      transition: "1000ms",
                    }}>
                      <h4 style={{
                        marginTop: DeviceDetect.isMobileOrTablet() ? 8:8,
                        color:"white",
                        fontFamily:Theme.secondaryFont,
                        textAlign:"center",
                        textShadow:"0px 0px 1px rgba(255, 255, 255, 0.8)",
                        fontSize: DeviceDetect.isMobileOrTablet() ? 15:18,
                        userSelect: "none",
                      }}>
                        Solutions built from the problem up
                      </h4>
                    </div>
                  )}
                </Transition>

                {!DeviceDetect.isMobileOrTablet() && !this.state.hideMain && 
                  <div style={{
                    width:"100%",
                    maxWidth:900,
                    // display:"flex",
                    flexDirection:"column",
                    justifyContent:"center",
                    // backgroundColor:"red",
                    position:"fixed",
                    top:"50%",
                    left:"50%",
                    transform: 'translate(-50%, -50%)',
                    marginTop:0,
                    opacity:buttonOpacity,
                    display: buttonOpacity<0.1 ? "none":"block",
                  }}>
                    <div style={{
                      width:"100%",
                      display:"flex",
                      justifyContent:"center",
                      // backgroundColor:"green",
                      marginTop:10,
                    }}>
                      <div style={{
                        width:100
                      }}></div>
                      <PrimaryButton title={"overview"} icon={<LandscapeIcon />}
                        onClick={()=>{
                          this.overviewRef.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                            inline: 'start',
                          });
                        }}
                        />
                      <div style={{
                        flexGrow:1,
                      }}></div>
                      <PrimaryButton title={"projects"} icon={<HistoryIcon />}
                        onClick={()=>{
                          this.projectsRef.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                            inline: 'start',
                          });
                        }}
                        />
                      <div style={{
                        width:100
                      }}></div>
                    </div>
                    <div style={{
                      width:"100%",
                      display:"flex",
                      justifyContent:"center",
                      // backgroundColor:"green",
                      marginTop:80,
                    }}>
                      <div style={{
                        width:20
                      }}></div>
                      <PrimaryButton title={"team"} icon={<GroupIcon />}
                        onClick={()=>{
                          this.teamRef.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                            inline: 'start',
                          });
                        }}
                        />
                      <div style={{
                        flexGrow:1,
                      }}></div>
                      <PrimaryButton title={"services"} icon={<RoomServiceIcon />}
                        onClick={()=>{
                          this.servicesRef.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                            inline: 'start',
                          });
                        }}
                        />
                      <div style={{
                        width:20
                      }}></div>
                    </div>
                    <div style={{
                      width:"100%",
                      display:"flex",
                      justifyContent:"center",
                      // backgroundColor:"green",
                      marginTop:80,
                    }}>
                      <div style={{
                        width:100
                      }}></div>
                      <PrimaryButton title={"process"} icon={<SettingsIcon />}
                        onClick={()=>{
                          this.processRef.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                            inline: 'start',
                          });
                        }}
                        />
                      <div style={{
                        flexGrow:1,
                      }}></div>
                      
                      <PrimaryButton title={"contact"} icon={<EmailIcon />}
                        onClick={()=>{
                          this.contactRef.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                            inline: 'start',
                          });
                        }}
                        />
                      <div style={{
                        width:100
                      }}></div>
                    </div>
                  </div>      
                
                }

          
                {DeviceDetect.isMobileOrTablet() && !this.state.hideMain && 
                  <div style={{
                    marginTop:10,
                    // position:"sticky",
                    // top:0,
                    position:"fixed",
                    bottom:25,
                    left:25,
                    zIndex:100,
                    opacity:buttonOpacity,
                    display: buttonOpacity<0.1 ? "none":"block",
                  }}>
                    <PrimaryButton 
                    title={"Press"} 
                    icon={<TouchAppIcon/>}
                    onTouchStart={()=>this.setState({buttonLongPress:true})}
                    onTouchEnd={()=>this.setState({buttonLongPress:false})}                    
                    />
                  </div>
                }



          
              <div style={{
                height:"100vh",
                width:0,
                userSelect:"none",
              }}
              >
              </div> 

              <div ref={this.overviewRef} style={{marginTop:150}}></div>
              <SectionHeader 
              title={"Overview"}              
              />              
              <Overview/>

              <div ref={this.projectsRef} style={{marginTop:150}}></div>
              <SectionHeader 
              title={"Projects"}              
              />              
              <Projects 
                onDetail={(index)=>{
                  this.setState({
                    projectDetailIndex:index,
                    hideMain:true,
                    hideMenuButton:true,
                  });
                }}
              />

              <div ref={this.teamRef} style={{marginTop:150}}></div>
              <SectionHeader 
              title={"Team"}              
              />              
              <Team/>

              <div ref={this.servicesRef} style={{marginTop:150}}></div>
              <SectionHeader 
              title={"Services"}              
              />              
              <Services/>

              <div ref={this.processRef} style={{marginTop:150}}></div>
              <SectionHeader 
              title={"Process"}              
              />    
              <Process/>

              <div ref={this.contactRef} style={{marginTop:150}}></div>
              <SectionHeader 
              title={"Contact Us"}              
              />              
              <Contact/>
  
              <div style={{
                height:500,
              }}>                
              </div>
              <Footer/>
              <div style={{
                height:100,
              }}>                
              </div>

            </div>
            </div>
            
          </div>
        )}
      </Transition>
    );
  }

}


export default App;

class SectionHeader extends React.Component{
  constructor(props){
    super(props);
    this.state={  
    };

  }


  componentDidMount(){

  }

  render() {
    return(
      <div style={{
        fontFamily:Theme.primaryFont,
        fontWeight:700,
        fontSize:45,
        color:"white",
        marginBottom:40,
        alignSelf:"flex-start",
        display:"flex",
        textShadow:"0px 0px 4px rgba(255, 255, 255, 0.8)",
      }}
      ref={this.props.ref}
      >
        <img 
        style={{
          flexShrink:0,
        }}
        src={SquareLogo} 
        alt={"AIsoft logo"} 
        width={50} 
        height={50}/>
        <div style={{
          marginLeft:20,
        }}>
          {this.props.title}
        </div>
      </div>
    );
  }

}