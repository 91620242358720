import React from 'react';
import '../App.css';
import {PrimaryButton} from "./buttons.component";

import HistoryIcon from '@material-ui/icons/History';
import HomeIcon from '@material-ui/icons/Home';
import GroupIcon from '@material-ui/icons/Group';
import EmailIcon from '@material-ui/icons/Email';
import SettingsIcon from '@material-ui/icons/Settings';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import LandscapeIcon from '@material-ui/icons/Landscape';
import { Transition } from 'react-transition-group';

class Menu extends React.Component{
  constructor(props){
    super(props);
    this.state={      
    };

  }


  componentDidMount(){

  }

  render() {
    let spacing=15;
    let enterSpeed = "500ms";
    let stagerTimeout = 75;
    return(
      <div style={{
        position:"fixed",
        width:"100vw",
        height:"100vh",
        backgroundColor:"rgba(0, 0, 0, 0.2)",
        opacity:1, 
        zIndex:10,
        color:"white",
        display:this.props.show ? "flex":"none",
        flexDirection:"column",
        alignItems:"center",
        // justifyContent:"space-around",
        paddingTop:60,
        // marginBottom:200,
      }}
      onClick={()=>{
        this.props.closeFunction();
      }}
      >
      <Transition in={this.props.show} timeout={stagerTimeout*0}>
        {state => (
          <div style={{
            marginBottom:spacing,
            transform:state==="entering" ? "translateX(-2000px)":"translateX(0px)",
            transition: enterSpeed,
          }}>
            <PrimaryButton title={"home"} icon={<HomeIcon />}
            onClick={()=>{
              this.props.topRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'start',
              });
              this.props.closeFunction();
            }}
            />
          </div>
        )}
      </Transition>
      <Transition in={this.props.show} timeout={stagerTimeout*1}>
        {state => (
          <div style={{
            marginBottom:spacing,
            transform:state==="entering" ? "translateX(-2000px)":"translateX(0px)",
            transition: enterSpeed,
          }}>
            <PrimaryButton title={"overview"} icon={<LandscapeIcon />}
              onClick={()=>{
                this.props.overviewRef.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'start',
                });
                this.props.closeFunction();
              }}
            />
          </div>
        )}
      </Transition>
      <Transition in={this.props.show} timeout={stagerTimeout*2}>
        {state => (
          <div style={{
            marginBottom:spacing,
            transform:state==="entering" ? "translateX(-2000px)":"translateX(0px)",
            transition: enterSpeed,
          }}>
            <PrimaryButton title={"projects"} icon={<HistoryIcon />}
              onClick={()=>{
                this.props.projectsRef.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'start',
                });
                this.props.closeFunction();
              }}
            />
          </div>
        )}
      </Transition>
      <Transition in={this.props.show} timeout={stagerTimeout*3}>
        {state => (
          <div style={{
            marginBottom:spacing,
            transform:state==="entering" ? "translateX(-2000px)":"translateX(0px)",
            transition: enterSpeed,
          }}>
            <PrimaryButton title={"team"} icon={<GroupIcon />}
              onClick={()=>{
                this.props.teamRef.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'start',
                });
                this.props.closeFunction();
              }}
            />
          </div>
        )}
      </Transition>

      <Transition in={this.props.show} timeout={stagerTimeout*4}>
        {state => (
          <div style={{
            marginBottom:spacing,
            transform:state==="entering" ? "translateX(-2000px)":"translateX(0px)",
            transition: enterSpeed,
          }}>
            <PrimaryButton title={"services"} icon={<RoomServiceIcon />}
              onClick={()=>{
                this.props.servicesRef.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'start',
                });
                this.props.closeFunction();
              }}
            />
          </div>
        )}
      </Transition>


      <Transition in={this.props.show} timeout={stagerTimeout*5}>
        {state => (
          <div style={{
            marginBottom:spacing,
            transform:state==="entering" ? "translateX(-2000px)":"translateX(0px)",
            transition: enterSpeed,
          }}>
            <PrimaryButton title={"process"} icon={<SettingsIcon />}
              onClick={()=>{
                this.props.processRef.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'start',
                });
                this.props.closeFunction();
              }}
            />
          </div>
        )}
      </Transition>  

      <Transition in={this.props.show} timeout={stagerTimeout*6}>
        {state => (
          <div style={{
            marginBottom:spacing,
            transform:state==="entering" ? "translateX(-2000px)":"translateX(0px)",
            transition: enterSpeed,
          }}>
            <PrimaryButton title={"contact"} icon={<EmailIcon />}
              onClick={()=>{
                this.props.contactRef.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'start',
                });
                this.props.closeFunction();
              }}
            />
          </div>
        )}
      </Transition>
   
        
      </div>
    );
  }

}


export default Menu;
