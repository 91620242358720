import React from 'react';
import '../App.css';
import Theme from '../theme';
import PointCursor from "../assets/img/point_cursor.png";

class Footer extends React.Component{
  constructor(props){
    super(props);
    this.state={   
        
    };

  }


  componentDidMount(){

  }

  render() {
    return(
      <div style={{
        display:"flex",
        width:"100%",
        justifyContent:"space-between",
        color:"white",
        fontFamily:Theme.secondaryFont,      
      }}
      >
        <div>
          Copyright ©2020 AISOFT DEVELOPMENT LLC All rights reserved
        </div>
        <a 
        style={{
          color:"white",
          textDecoration:"none",
          cursor:`url(${PointCursor}), auto`,
        }}
        href="https://www.freeprivacypolicy.com/privacy/view/2afd6ae9e6b63d44e64efc1647fc0e2d" 
        target="_blank" 
        rel="noopener noreferrer">
          Privacy Policy
        </a>
      </div> 
    );
  }

}


export default Footer;
