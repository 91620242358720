import React from 'react';
import '../App.css';
import * as THREE from "three";

class Graph extends React.Component{
  constructor(props){
    super(props);
    this.state={
      signupHover:false,
      clock: new THREE.Clock(),
      isMobile:window.innerWidth <= 760,
    };

    this.scene=null;
    this.camera=null;
    this.renderer=null;

    this.animate = this.animate.bind(this);

  }


  componentDidMount() {

      this.scene = new THREE.Scene();      
      this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: 1 });    
      this.renderer.setClearColor( 0xffffff, 0);
    
      this.mount.appendChild( this.renderer.domElement );

        this.renderer.setSize( window.innerWidth, window.innerHeight );
        this.camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );
        this.camera.position.z = 3;

        // if(this.state.isMobile){
        //   this.controls = new OrbitControls( this.camera, this.renderer.domElement );
        //   // this.controls.autoRotate=true;
        //   this.controls.update();
        // }

        const color = 0xFFFFFF;
        const intensity = 0.5;
        this.light = new THREE.DirectionalLight(color, intensity);
        this.light.position.set(1, 1, 4);
        this.scene.add( this.light );

        let ambiantLight = new THREE.AmbientLight( 0xffffff,0.3 );
        this.scene.add( ambiantLight );

        let segments = 12;
        var sphereGeometry = new THREE.SphereBufferGeometry(0.2,segments,segments);
        // var sphereGeometry = new THREE.BoxGeometry(0.2,0.2,0.2);
        // var sphereGeometry = new THREE.TorusKnotGeometry( 0.15, 0.05, 100, 16 );
        // var boxGeometry  = new THREE.SphereGeometry(0.2,segments,segments);
        // var boxGeometry = new THREE.BoxGeometry(0.2,0.2,0.2);
        // sphereGeometry.wireframe=true;
        // var sphereMaterial = new THREE.MeshBasicMaterial( { color: 0x00EF2D79 } );
        let sphereMaterial = new THREE.MeshPhongMaterial( { 
            color: 0x00EF2D79,
            shininess:100,
            // wireframe:true,
        } ); 

        // let sphereMaterial = new THREE.MeshStandardMaterial( { 
        //   color: 0x00EF2D79,
        //   roughness:0.1,
        //   // shininess:100,
        //   // wireframe:true,
        // } ); 
        // sphereGeometry.radius=0.1;
        this.sphere = new THREE.Mesh( sphereGeometry, sphereMaterial );
        this.scene.add(this.sphere);

        
        // var waterSphereGeometry = new THREE.SphereGeometry(0.2,segments,segments);

        // // Init water
        // this.waterGeometry = new THREE.PlaneBufferGeometry( 10000, 10000 );
        // this.water = new Water(
        //   waterSphereGeometry,
        //   // this.waterGeometry ,
        //   {
        //     textureWidth: 512,
        //     textureHeight: 512,
        //     // textureWidth: 100,
        //     // textureHeight: 100,

        //     waterNormals: new THREE.TextureLoader().load( WaterNormals, function ( texture ) {
    
        //       texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

    
        //     } ),
        //     alpha: 0.0,
        //     sunDirection: this.light.position.clone().normalize(),
        //     sunColor: 0xffffff,
        //     waterColor: 0x001e0f,
        //     distortionScale: 0.0,//3.7,
        //     fog: this.scene.fog !== undefined,
        //     // size:0.1,
        //   }
        // );
        // this.water.material.uniforms.size.value = 10;
        // this.water.rotation.x = - Math.PI / 2;
        // this.scene.add(this.water);


        // this.water = new Water( waterSphereGeometry, {
        //   color: '#ffffff',
        //   scale: 10,
				// 	flowDirection: new THREE.Vector2( 1, 1 ),
				// 	textureWidth: 1024,
				// 	textureHeight: 1024
				// } );

				// this.water.position.y = 1;
				// this.water.rotation.x = Math.PI * - 0.5;
				// this.scene.add( this.water );


        

        // let offset = 0.5;
        // let box1 = new THREE.Mesh( boxGeometry, sphereMaterial );
        // box1.position.x=offset;
        // box1.position.y=-offset;
        // box1.position.z=-offset;
        // this.scene.add(box1);

        // let box2 = new THREE.Mesh( boxGeometry, sphereMaterial );
        // box2.position.x=-offset;
        // box2.position.y=-offset;
        // box2.position.z=-offset;
        // this.scene.add(box2);

        // let box3 = new THREE.Mesh( boxGeometry, sphereMaterial );
        // box3.position.x=offset;
        // box3.position.y=offset;
        // box3.position.z=-offset;
        // this.scene.add(box3);

        // let box4 = new THREE.Mesh( boxGeometry, sphereMaterial );
        // box4.position.x=-offset;
        // box4.position.y=offset;
        // box4.position.z=-offset;
        // this.scene.add(box4);
        
        // // Front
        
        // let box5 = new THREE.Mesh( boxGeometry, sphereMaterial );
        // box5.position.x=offset;
        // box5.position.y=-offset;
        // box5.position.z=offset;
        // this.scene.add(box5);

        // let box6 = new THREE.Mesh( boxGeometry, sphereMaterial );
        // box6.position.x=-offset;
        // box6.position.y=-offset;
        // box6.position.z=offset;
        // this.scene.add(box6);

        // let box7 = new THREE.Mesh( boxGeometry, sphereMaterial );
        // box7.position.x=offset;
        // box7.position.y=offset;
        // box7.position.z=offset;
        // this.scene.add(box7);

        // let box8 = new THREE.Mesh( boxGeometry, sphereMaterial );
        // box8.position.x=-offset;
        // box8.position.y=offset;
        // box8.position.z=offset;
        // this.scene.add(box8);

        // let coneGeometry = new THREE.CylinderGeometry( 0.1, 0.1, 5, 32 );
        // let coneMaterial = new THREE.MeshPhongMaterial( { 
        //     color: 0x00EF2D79,
        //     shininess:100,
        //     // wireframe:true,
        // } ); 
        // let cone = new THREE.Mesh( coneGeometry, coneMaterial );
        // cone.position.x=2;
        // this.scene.add(cone);


      // scene.fog = new THREE.Fog(0xd4d4d4, 8, 20);

      // Create vertex points
      this.wireGeometry = new THREE.IcosahedronGeometry(0.33, 1); // radius, detail
      this.wirePointPositions = [];
      for (var i = 0, l = this.wireGeometry.vertices.length; i < l; i++) {
        this.wirePointPositions[i] = new THREE.Vector3(this.wireGeometry.vertices[i].x,this.wireGeometry.vertices[i].y,this.wireGeometry.vertices[i].z);
      }

      // let wireVertices = this.wireGeometry.vertices;
      // this.wirePointPositions = new Float32Array(this.wireGeometry.vertices.length * 3);
      // for (var i = 0, l = this.wireGeometry.vertices.length; i < l; i++) {
      //   this.wireGeometry.vertices[i].toArray(this.wirePointPositions, i * 3);
      // }
      // let pointGeometry = new THREE.BufferGeometry();
      // pointGeometry.addAttribute('position', new THREE.BufferAttribute(wirePointPositions, 3));
      // let pointsMaterial = new THREE.PointsMaterial({
      //   size: 0.4,
      //   vertexColors: THREE.VertexColors,
      //   color: 0x252525,
      // });
      // let points = new THREE.Points(pointGeometry, pointsMaterial);
      // points.geometry.attributes.position.array[1]=-5;
      // let object = new THREE.Object3D();
      // object.add(points);

      // object.add(new THREE.Mesh(
      //   wireGeometry,
      //   new THREE.MeshPhongMaterial({
      //     color: 0x616161,
      //     emissive: 0xa1a1a1,
      //     wireframe: true,
      //     fog: 1
      //   })
      // ));


      // wireGeometry.vertices[1].x = -5;
      // points.geometry.vertices[0].x = 5;
      
      // wireGeometry.vertices[1].x = -5;
      
      // let wireMaterial= new THREE.MeshPhongMaterial({
      //   color: 0x00EF2D79,
      //   // emissive: 0xa1a1a1,
      //   wireframe: true,
      //   fog: 1
      // });

      let wireMaterial=new THREE.MeshBasicMaterial({
        color: 0x00EF2D79,
        // emissive: 0xa1a1a1,
        wireframe: true,
        wireframeLinewidth:2,
        // wireframeLinejoin:"bevel",
        // fog: 1
      });

    //   let wireMaterial = new THREE.MeshPhongMaterial( { 
    //     color: 0x00EF2D79,
    //     shininess:100,
    //     wireframe:true,      
    //     wireframeLinewidth:3,  
    //     emissive: 0x00EF2D79,
    //     emissiveIntensity:0.5,
    // } ); 

      let wireMesh = new THREE.Mesh(this.wireGeometry,wireMaterial);
      this.scene.add(wireMesh);

      // this.camera.position.z = 20;

      // var render = function() {
      //   requestAnimationFrame(render);

      //   object.rotation.x += 0.01;
      //   object.rotation.y += 0.01;

      //   renderer.render(scene, camera);
      // };

      // render();

      this.animate();
  }

  animate() {
    requestAnimationFrame( this.animate );
    // this.camera.position.x = (-(window.innerWidth/2 - this.state.mousePositionX)/500);
    // this.camera.position.y = ((window.innerHeight/2 - this.state.mousePositionY)/500);
    // this.camera.lookAt( 0,0,0 );
    let time = this.state.clock.getElapsedTime();

    // if(this.state.isMobile){      
    //   this.controls.update();
    // }
    

    // this.sphere.rotation.x = time*1;
    // this.sphere.rotation.y = time*1;
    // this.sphere.rotation.z = time*2;
    // this.water.material.uniforms.time.value = time/10;

    let frequency=3;
    // let magnitude=0.0001;
    let magnitude=0.1;

    let expansion;
    if(this.props.isMobile){
      let minX=-3;
      let maxX=3;
      this.camera.position.x = (this.props.scrollPercentage*(maxX-minX))+minX;

      let minExpansion=-1;
      let maxExpansion=1;
      let expandPerc = this.props.scrollPercentage;
      if(this.props.scrollPercentage>0.5){
        expandPerc=1-expandPerc;
      }

      expansion = maxExpansion-(expandPerc*(maxExpansion-minExpansion)) ;
      // this.camera.position.x = 2;
    }else{
      this.camera.position.x = (-(window.innerWidth/2 - this.props.mousePositionX)/500);
      this.camera.position.y = ((window.innerHeight/2 - this.props.mousePositionY)/500);
      let mouseDistaceFromCenterX = Math.abs(window.innerWidth/2 - this.props.mousePositionX);
      let mouseDistaceFromCenterY = Math.abs(window.innerHeight/2 - this.props.mousePositionY);
      expansion = (mouseDistaceFromCenterX+mouseDistaceFromCenterY)*0.001;
    }
    this.camera.lookAt( 0,0,0 );

    // console.log(mouseDistaceFromCenterX);

    // console.log(offset);
    for (let index = 0; index < this.wireGeometry.vertices.length; index++) {
      
      let shift = index*expansion;
      // magnitude=magnitude*expansion;
      if(this.props.buttonLongPress){
        expansion=this.lastExpansion-0.01;
        shift=0;
      }
      let offset = magnitude*Math.sin((time*frequency)+(shift))+(expansion);

      // const currVertex = this.wireGeometry.vertices[index];
      
      let origin = this.wirePointPositions[index];
      let direction = origin.clone().normalize();
      let pointNew = origin.clone().addScaledVector(direction, offset);
      this.wireGeometry.vertices[index].x= pointNew.x;
      this.wireGeometry.vertices[index].y= pointNew.y;
      this.wireGeometry.vertices[index].z= pointNew.z;

      // this.wireGeometry.vertices[index].x= this.wirePointPositions[index].x + offset;
      // this.wireGeometry.vertices[index].y= this.wirePointPositions[index].y + offset;
      // this.wireGeometry.vertices[index].z= this.wirePointPositions[index].z + offset;

      // this.wireGeometry.vertices[index] = THREE.Vector3(currVertex.x,currVertex.y,currVertex.z);
      // if(currVertex.x){
      //   // this.wireGeometry.vertices[index]= THREE.Vector3(currVertex.x,currVertex.y,currVertex.z);
      //   this.wireGeometry.vertices[index].x= currVertex.x+2;
      // }
      // console.log(currVertex.x);        
    }
    this.wireGeometry.verticesNeedUpdate = true;
    // let vertices = this.wireGeometry.vertices;
    // if(this.wireGeometry.vertices.length>0){
    // }
    

    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize( window.innerWidth, window.innerHeight );

    this.renderer.render( this.scene, this.camera );
    this.lastExpansion=expansion;
  };

  render() {
    return(
      <div style={{      
        height:"100%",
        width:"100%",
        alignItems:"center",
        position: "fixed",
        zIndex:-7,
        opacity:this.props.opacity,
      }}
      // onMouseMove={(e)=>{
      //   this.setState({mousePositionX:e.pageX,mousePositionY:e.pageY});
      // }}
      >
        <div 
        style={{
        position: "absolute",
        zIndex:-8,
        margin:0,
        height:"100%",
        width:"100%",
        opacity:0.0,
        }}
        ></div>             
        <div 
        ref={ref => (this.mount = ref)} 
        style={{
        position: "absolute",
        zIndex:-9,
        margin:0,
        height:"100%",
        // opacity:0.5,
        }}
        ></div>
        
      </div>
    );
  }

}


export default Graph;
